
@font-face {
    font-family: Children;
    src: local('Children'), url(/public/assets/fonts/ChildrenRegular/Children.ttf);
}

/* Reset margin and padding */
body, html {
    margin: 0;
    padding: 0;
    overscroll-behavior: contain;
    touch-action: pan-y;
    @apply bg-paper bg-repeat bg-center;
    background-size: contain auto; /* Default size for desktop */
}

@media (max-width: 768px) {
    body, html {
        background-size: 125% auto; /* Smaller repeat pattern for mobile */
    }
}
/* Page container */
.page-container {
    margin: 0;
    padding: 0;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    touch-action: pan-y;
    position: relative;
    z-index: 1;
    overflow-x: hidden; /* Prevent horizontal scrolling */

}

/* Main content */
.main-content {
    flex: 1;
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    position: relative;
    z-index: 1;
    overscroll-behavior: contain;


}


/* Footer styles */
footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: transparent;
}

/* Ensure images and content do not exceed viewport width */
img, video, canvas, svg {
    max-width: 100%;
    height: auto;
}

/* * {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
}

@media (max-width: 768px) {
    body{
        background-size: contain;
    }

}  */

